.RecordButton {
  position: absolute;
  z-index: 10;
  right: 15px;
  bottom: 15px;
}

.RecordButton-ring {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.RecordButton-ring__circle {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.RecordButton_btn {
  width: 30px;
  height: 30px;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  background-color: #fff;
  -webkit-tap-highlight-color: transparent;
  border: 3px solid #fff9;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
}

.RecordButton_btn._active {
  background-color: #d33;
  border-color: #fff9;
  animation: 1.5s ease-in 0s infinite alternate recordAnimation;
}

@keyframes recordAnimation{
  0%{
    transform: scale(0.3);
  }
  50%{
    transform: scale(0.35);
  }
  100%{
    transform: scale(0.3);
  }
}